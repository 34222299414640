<template>
  <v-dialog v-model="show" :max-width="500" scrollable hide-on-leave>
    <template v-if="showActivator" v-slot:activator="{ on, attrs }">
      <v-btn :text="!minimal" color="red lighten-2" dark v-bind="attrs" v-on="on" :icon="minimal">
        <span v-if="!minimal"><v-icon left>mdi-plus-circle</v-icon>Conditions</span>
        <v-icon v-if="minimal">mdi-plus-circle-outlind</v-icon>
      </v-btn>

      <!-- <v-list-item
            
            v-if="listItem"
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-content>
<v-list-item-title class="font-weight-medium text-subtitle-2 text--disabled">
    Update Conditions
</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
                <v-icon color="primary" >mdi-plus-circle</v-icon>
            </v-list-item-action>
          </v-list-item> -->
    </template>
    <v-card min-height="300" class="pa-3 px-4">
      <v-row align="center" no-gutters justify="space-between" class="font-weight-bold text-h6">
        <span v-if="patient && patient.firstName && patient.lastName"
          >{{ patient.firstName }} {{ patient.lastName }}'s Conditions
        </span>
        <!-- <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn> -->
      </v-row>

      <v-autocomplete
        :allow-overflow="false"
        class="mt-3"
        ref="search"
        type="search"
        solo
        background-color="grey lighten-3"
        flat
        :menu-props="{ offsetOverflow: false, maxWidth: 468 }"
        prepend-inner-icon="mdi-magnify"
        autocomplete="off"
        clearable
        v-model="selectedCondition"
        return-object
        item-text="description"
        :items="conditionOptions"
        :search-input.sync="conditionSearch"
        hide-details
        label="Condition name or ICD-10 code"
      >
        <template v-slot:no-data>
          <v-card-text v-if="lookingUpProblem">
            Processing
          </v-card-text>
          <v-card-text v-else-if="!conditionSearch || conditionSearch.length === 0">
            You can search by common name or by ICD-10 code.
          </v-card-text>
          <v-card-text v-else>
            No matching Results
          </v-card-text>
        </template>
      </v-autocomplete>

      <v-card min-height="150" flat color="transparent">
        <v-row class="mt-4" v-if="patient" no-gutters>
          <v-chip
            class="mr-2 mb-2"
            label
            color="primary"
            v-for="(item, index) in conditionsArray"
            :key="index"
            close
            @click:close="removeProblem(item)"
            ><span class="d-inline-block text-truncate" style="max-width: 400px;">
              {{ item.name }}
            </span>
          </v-chip>

          <!-- <v-chip color="primary" close class="mr-2 mb-2" label v-for="(tag, index) in patient.tags" :key="index"  @click:close="removeTagFromList(tag)">
            {{tag}}</v-chip>

            <v-chip class="mr-2 mb-2"
          label
          v-show="!tag.applied"
          v-for="(tag, index) in tagsForDisplay"
          :key="index + tag" 
          :color="chipColor(tag)"
          @click="currentTargetInput = tag.title, submit()"
          outlined
          >
            {{tag.title}}</v-chip> -->
        </v-row>
      </v-card>

      <v-row no-gutters class="mx-0 mt-4">
        <!-- Buttons -->
        <v-btn depressed @click="cancelOption()">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed @click="cancelOption()">Done</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>

<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import { mapActions, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import { mask } from 'vue-the-mask'
import bus from '@/core/helpers/bus'
import get from 'lodash/get'

export default {
  name: 'PatientConditionsDialog',
  mixins: [DialogMixin, HelperMixin],
  components: {},
  directives: {
    mask,
  },
  props: {
    patient: {
      type: Object,
      default: null,
    },
    minimal: {
      type: Boolean,
      deafult: false,
    },
    listItem: {
      type: Boolean,
      deafult: false,
    },
    showActivator: {
      type: Boolean,
      deafult: true,
    },
  },
  data() {
    return {
      showLookup: false,
      conditionSearch: null,
      conditionOptions: [],
      selectedCondition: null,
      show: false,
      selectedUser: null,
      userList: [],
      rawGroupMembers: [],
      selectedTag: null,
      tagInputValue: null,
      currentTargetInput: null,
      timesOpened: 0,
    }
  },
  watch: {
    selectedTag(val) {
      this.currentTargetInput = val
    },
    showLookup(val) {
      if (val) {
        // this.$nextTick(() => (this.$refs.search.focus()))
        this.$nextTick(() => this.$refs.search.activateMenu())
      }
    },
    conditionSearch(val) {
      if (val) {
        this.lookupProblem({ terms: val }).then(data => {
          this.conditionOptions = data
        })
      }
    },
    selectedCondition(val) {
      if (val) {
        this.addProblem(val)
      }
    },
  },
  methods: {
    ...mapActions('auth', ['addChartNote', 'userActivity']),
    ...mapActions('patient', ['lookupProblem', 'updatePatientConditions']),
    // go get the taskboard to update the totals
    ...mapActions('taskboard', ['getEnrolledPatients', 'removeTagLocal', 'addTagLocal']),
    // for when we show the short list of conditions
    chipColor(tag) {
      if (tag.applied) {
        return 'primary'
      }
      return null
    },
    addProblem(problem) {
      this.$refs.search.blur()
      this.$nextTick(() => (this.selectedCondition = null))
      this.$nextTick(() => (this.conditionSearch = null))
      this.$nextTick(() => (this.conditionOptions = null))
      let conditionList = this.patient.conditions
      let canPush = true
      conditionList.forEach(element => {
        if (element === problem.compound) {
          canPush = false
        }
      })
      if (canPush) {
        conditionList.push(problem.compound)
        this.updatePatientConditions({
          patientID: this.$route.params.id,
          conditionList: conditionList,
        })
        let objectProto = problem.compound.split('|')
        let problemObject = {}
        problemObject.name = objectProto[1]
        if (objectProto[1]) {
          problemObject.name = objectProto[0] + ' ' + objectProto[1]
        }
        problemObject.icd10 = objectProto[0]
        problemObject.fullItem = problem

        bus.$emit('toast', { type: 'success', text: 'Condition Added' })

        this.addChartNote({
          patient: this.$route.params.id,
          partner: this.partner._id,
          sessionNote: 'Updated condition list.',
          type: 'auto',
          action: 'add',
          referenceData: { type: 'condition', data: problemObject },
        }).then(() => {
          bus.$emit('refreshNoteList')
        })

        this.userActivity({ patient: this.$route.params.id, type: 'update_problem_list' })
      }
    },
    removeProblem(problem) {
      let conditionList = this.patient.conditions
      let index = conditionList.indexOf(problem.fullItem)
      if (index > -1) {
        conditionList.splice(index, 1)
      }
      this.updatePatientConditions({
        patientID: this.$route.params.id,
        conditionList: conditionList,
      })

      bus.$emit('toast', { type: 'error', text: 'Condition Removed' })
      this.addChartNote({
        patient: this.$route.params.id,
        partner: this.partner._id,
        sessionNote: 'Updated condition list.',
        type: 'auto',
        action: 'remove',
        referenceData: { type: 'condition', data: problem },
      }).then(() => {
        bus.$emit('refreshNoteList')
      })

      this.userActivity({ patient: this.$route.params.id, type: 'update_problem_list' })
    },
    onClose() {},
    cancelOption() {
      this.cancel()
    },
  },
  computed: {
    ...mapState('auth', ['partner', 'user']),
    // get the full list of conditions fron the taskboard
    ...mapState('taskboard', ['patientTags']),
    ...mapState('patient', ['lookingUpProblem']),
    conditionsArray() {
      let conditions = []
      if (this.patient && this.patient.conditions) {
        this.patient.conditions.forEach(problem => {
          let objectProto = problem.split('|')
          let problemObject = {}
          problemObject.name = objectProto[1]
          if (objectProto[1]) {
            problemObject.name = objectProto[0] + ' ' + objectProto[1]
          }
          problemObject.icd10 = objectProto[0]
          problemObject.fullItem = problem
          conditions.push(problemObject)
        })
      }
      return conditions
    },
    // this splits the aplied ones and the not applied ones
    tagsForDisplay() {
      let tagsToReturn = []
      this.patientTags.forEach(tag => {
        if (get(this.patient, 'tags.length', 0) > 0 && this.patient.tags.includes(tag)) {
          let primary = this.patient.tags[0] === tag
          tagsToReturn.push({ title: tag, applied: true, primary: primary })
        } else {
          tagsToReturn.push({ title: tag, applied: false, primary: false })
        }
      })
      return tagsToReturn
    },
    submitTitle() {
      return 'Add'
    },
  },
  mounted() {
    this.$on('open', this.onOpen)
    this.$on('close', this.onClose)
  },
}
</script>
